footer {
  color: $white;
  font-size: 10px;
  margin-top: 30px;
  padding: 20px 0;
  background: $second_color;
  h3 {
    font-family: $Nunito-SemiBold;
    font-size: 12px;
    margin-bottom: 12px;
  }
  li {
    font-size: 10px;
    line-height: 120%;
    margin-bottom: 8px;
  }
  a {
    color: $white;
  }
  a:hover {
    color: $third_color;
  }
  span {
    font-size: 12px !important;
  }
  p {
    margin-bottom: 8px;
  }
  .footer-contacts {
    .social-links {
      justify-content: flex-start;
    }
  }
  .copy {
    line-height: 160%;
  }
  .up {
    width: 50px;
    height: 74px;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    position: absolute;
    top: -45px;
    right: 12px;
    text-align: center;
    cursor: pointer;
  }
  .up::before {
    content: '';
    display: block;
    background: url('../assets/images/arrow.svg') center center no-repeat, #fff;
    width: 50px;
    height: 50px;
    border-radius: 25px;
    margin-bottom: 12px;
    border: 1px solid $second_color;
    box-sizing: border-box;
  }
  .up:hover {
    color: $third_color;
  }
}
@media (max-width: 920px) {
  .up {
    right: 15px !important;
  }
}