@import "../constants.scss";

/* Some Resets and Presets */

* {
  font-family: $Nunito-Regular;
}
a {
  text-decoration: none;
}
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
hr {
  background: rgba(121, 122, 124, 0.5);
  width: 100%;
  height: 1px;
  margin: 24px auto 32px;
}
input,
textarea {
  outline: none;
}

// for images
.responsive {
  min-width: 100%;
}

// Mobile version OFF!!!
//min-width: 1100px!important;

.wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
html,
body {
  height: 100%;
}
main {
  flex: 1 0 auto;
}

header {
  flex: 0 0 auto;
}

footer {
  flex: 0 0 auto;
}

header,
main,
footer,
.main_panel,
.service {
  .container {
    max-width: 920px;
    position: relative;
  }
}

/* Scrolls */

// Mixins

@mixin btn {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  width: 140px;
  height: 42px;
  line-height: 42px;
  font-family: $Nunito-Regular;
  color: $white;
  background: $third_color;
  text-align: center;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}
